import _classCallCheck from "/opt/build/repo/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/classCallCheck";
import _defineProperty from "/opt/build/repo/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
export var Nav = function Nav() {
  _classCallCheck(this, Nav);

  _defineProperty(this, "content", void 0);

  this.content = {
    header: [{
      name: 'header',
      link: {
        cached_url: '/',
        url: '/'
      },
      _uid: '2346274324'
    }],
    footer_nav: [{
      name: 'footerNav',
      link: {
        cached_url: '/',
        url: '/'
      },
      _uid: '2346274324'
    }],
    footer: [{
      name: 'footer',
      link: {
        cached_url: '/',
        url: '/'
      },
      _uid: '2346274324',
      sub_item: [{
        name: 'footerSubItem',
        link: {
          cached_url: '/',
          url: '/'
        },
        _uid: '2346274324'
      }]
    }],
    navigation_right: [{
      name: 'navigation_right',
      link: {
        cached_url: '/',
        url: '/'
      },
      _uid: '2346274324'
    }],
    login_text: [{
      name: 'Log in',
      link: {
        cached_url: '/',
        url: '/'
      },
      _uid: '2346274324'
    }],
    signup_text: [{
      name: 'sign up',
      link: {
        cached_url: '/',
        url: '/'
      },
      _uid: '2346274324'
    }],
    funding_description: '',
    footer_description: ''
  };
};